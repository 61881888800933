import Vue from 'vue'
import template from './template.vue'
import VueCookie from 'vue-cookie'
import VueSweetalert2 from 'vue-sweetalert2'

Vue.config.productionTip = false

const options = {
  confirmButtonColor: '#fff',
}

Vue.use(VueCookie);
Vue.use(VueSweetalert2, options)

new Vue({
  render: h => h(template),
}).$mount('#app')
