<template>
  <div class="wrapper">
    <page-header></page-header>
    <main class="main">
      <h3 class="title">用户登录</h3>
      <form action='/login' method='POST'>
        <div class="form">
          <!--用户名-->
          <div class="form-group" >
            <input type='text' name='username' class="form-control" placeholder="用户名或邮箱"/>
          </div>
          <!--密码-->
          <div class="form-group" >
            <input type="password" id="password" onchange="updatePassword()" class="form-control" placeholder="密码"/>
            <input type='text' id="md5password" name='password' class="form-control" style="display: none"/>
          </div>
          <!--提交-->
          <div class="form-group submitWrapper">
            <input name="submit" type="submit" class="form-control submitBtn" value="登录"/>
          </div>
        </div>
      </form>
    </main>
    <page-footer></page-footer>
  </div>
</template>

<script>
import pageHeader from '../../components/Header'
import pageFooter from '../../components/Footer'
import crypto from 'crypto'
export default {
  components: {
    pageHeader,
    pageFooter
  },
  mounted(){
    window.updatePassword = () => {
      let md5 = crypto.createHash('md5')
      const password = document.querySelector('#password').value
      const md5password = md5.update(password).digest('hex')
      document.querySelector('#md5password').value = md5password
    }
    if(this.$cookie.get('errorMsg')){
      this.$swal(this.$cookie.get('errorMsg'))
    }
    this.$cookie.delete('errorMsg')
  }
}
</script>


<style lang="stylus">
.wrapper{
  display: flex;
  height: 85vh;
  justify-content: space-between;
  flex-direction: column;
}
.main{
  margin: 0 auto;
  .title{
    color: #e96e36;
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 1.8rem;
    text-align: center;
  }
  .form{
    .form-group{
      margin-top: 2rem;
      width: 100vw;
      padding: 0 0.875rem;
      max-width: 23.5rem;
      input {
        display: inline-block;
        width: 100%;
        height: 40px;
        margin: 0 auto;
        font-size: 14px;
        text-indent: 3%;
        border: 1px solid #cdcdcd;
        border-radius: 4px;
        -webkit-user-select: text !important;
        -webkit-appearance: none;
        &:focus{
          border: 1px solid #f26f21;
        }
      }
    }
    .submitWrapper{
      input{
        font-size: 1.25rem;
        color: #fff;
        font-weight: 500;
        text-align: center;
        background: #E56C24;
        border-radius: 4px;
        border: none;
        cursor: pointer;
      }
    }
  }
}
.swal2-modal.swal2-show {
  height: 112px;
  width: 75vw;
  border-radius: 15px;
  .swal2-title{
    width: 75vw;
    max-width: none;
    font-size: 17px;
    color: #3b2c29;
    justify-content: center;
    font-weight: 400;
    padding-bottom: 22px;
    border-bottom: 1px solid #d6d6d6;
  }
  .swal2-actions{
    margin: 0;
    .swal2-styled.swal2-confirm{
      width: 100%;
      margin: 0;
      padding: 0;
      color: #f26f21;
      font-size: 17px;
      font-weight: 400;
      &:active
      &:hover{
        background-image: none;
      }
      &:focus{
        box-shadow: none;
      }
    }
  }
}
</style>
